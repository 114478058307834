import { type BenefitPeriod, BenefitPeriodEnum } from '$lib/benefitFilterTypes'
import type { ParamMatcher } from '@sveltejs/kit'

/**
 * Little hacky, but since code is optional,
 * we'll just assume it's a code if it's not a
 * benefit period.
 *
 * @param param
 * @returns boolean
 *
 */
export const match: ParamMatcher = (param) => {
  const key = param as BenefitPeriod
  return !BenefitPeriodEnum.options.includes(key)
}
