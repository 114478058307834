import { match as benefitPeriod } from "../../../src/params/benefitPeriod.ts";
import { match as benefitSection } from "../../../src/params/benefitSection.ts";
import { match as benefitType } from "../../../src/params/benefitType.ts";
import { match as code } from "../../../src/params/code.ts";
import { match as messageType } from "../../../src/params/messageType.ts";
import { match as number } from "../../../src/params/number.ts";
import { match as objectid } from "../../../src/params/objectid.ts";
import { match as resourceType } from "../../../src/params/resourceType.ts";
import { match as videoKey } from "../../../src/params/videoKey.ts";

export const matchers = { benefitPeriod, benefitSection, benefitType, code, messageType, number, objectid, resourceType, videoKey };