import * as Sentry from '@sentry/sveltekit'
import { PUBLIC_SENTRY_DSN, PUBLIC_VERCEL_ENV } from '$env/static/public'

Sentry.init({
  dsn: PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    }),
    Sentry.feedbackIntegration()
  ],
  environment: PUBLIC_VERCEL_ENV
})

export const handleError = Sentry.handleErrorWithSentry()
